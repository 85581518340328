@import "../../../assets/scss/veriables.scss";

//DAILOG BOX
.custom-dialog {
  // margin-top: 51px;
  ::-webkit-scrollbar {
    width: 0;
  }
  .dialog-content-box {
    // background: #fff;
    color: #fff;
  }
  .MuiDialog-paperWidthSm {
    @media (max-width: 1400px) {
      min-height: auto !important;
    }
  }
  .MuiDialog-paper {
    box-shadow: none;
    border-radius: 12px;
    max-width: inherit;
    width: 711px !important;
    padding: 60px;
    max-height: calc(100% - 60px);
    background-color: #201d17 !important;
    word-break: break-word;
    @media screen and (min-width: 320px) and (max-width: 440px) {
      padding: 25px;
    }
    // @media (max-width: 599px) {
    //   padding: 15px 12px;
    // }
    .MuiDialogTitle-root {
      padding: 0;
    }
    .custom-dialog-title {
      position: relative;
      min-height: 54px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .dialog-title {
        width: 100%;
        font-size: 22.4px;
        line-height: 24px;
        font-family: $dmSansFont;
        margin-left: 24px;
        margin-bottom: 20px;
        @media screen and (min-width: 320px) and (max-width: 440px) {
          margin: 0px 0px 20px 0px;
        }
        svg {
          @media (max-width: 599px) {
            width: 15px;
            height: 15px;
          }
        }
      }
      .dialog-close {
        color: $color-white;
      }
    }
  }
}

.pause-sync-btn {
  text-align: center;
  .btn-sec {
    width: 48%;
  }
  .blue-botton {
    background-color: rgba(49, 116, 45, 0.25) !important;
    border: 1px solid #31742d;
    border-radius: $border-radius !important;
    color: $color-white !important;
    text-transform: capitalize !important;
    font-family: $dmSansFont !important;
    font-size: 18px !important;
    font-weight: 500 !important;
    line-height: 20px !important;
    padding: 9px 0 !important;
  }
}
