@import "../../../../assets/scss/veriables.scss";

.main-wrapper-projects {
  // padding: 74px 156px 0px 156px;
  padding: 100px 156px 0px 156px;

  @media (max-width: 1024px) {
    padding: 34px 78px 0px 78px;
  }

  @media screen and (min-width: 320px) and (max-width: 440px) {
    // padding: 24px 34px 0px 34px;
    padding: 50px 34px 0px 34px;
  }
}

.all-projects-container {
  padding-bottom: 85px;

  .heading-projects-text {
    font-size: 30px;
    font-weight: 500;
    color: #F0F0DC;
    word-wrap: break-word;
    font-family: $regulerFont;
  }

  .text-font {
    font-size: 20px;
    font-weight: 400;
    font-family: $regulerFont;
    color: #F0F0DC;

    @media screen and (min-width: 320px) and (max-width: 425px) {
      font-size: 17px;
    }
  }
}

.projects-info-container {
  margin: 0px 100px 85px 100px;

  @media (max-width: 1024px) {
    margin: 0px 0px 50px 0px !important;
  }

  @media screen and (min-width: 1024px) and (max-width: 1350px) {
    margin: 0px 0px 50px 0px;
  }

  @media screen and (min-width: 320px) and (max-width: 425px) {
    margin: 0px 0px 42px 0px;
  }

  .heading-text {
    font-size: 26px;
    font-weight: 500;
    font-family: $regulerFont;
    margin: 0px;
  }

  .title-text {
    font-size: 15px;
    font-weight: 400;
    font-family: $regulerFont;
    text-decoration: underline;
  }

  // .info-text {
  //   font-size: 14px;
  //   font-weight: 300;
  //   font-family: $regulerFont;
  //   max-width: 250px;
  //   max-height: 144px;
  // }
  .projects-img {
    max-width: 100%;
    // height: 100%;

    // old css : 
    // max-width: 100%;
    // width: 649px;
    // height: 339px;
    // border-radius: 10px;

    // max-width: 649px;
    // width: 100%;
    // height: 100%;
    // min-height: 230px;
    // border-radius: 10px;

    @media screen and (min-width: 320px) and (max-width: 440px) {
      height: fit-content;
    }
  }

  .projects-detail-sec {
    display: flex;
    justify-content: space-between;

    @media (max-width: 1024px) {
      gap: 40px;
    }
    @media screen and (min-width: 1400px) and (max-width: 2560px){
      gap: 40px;
    }
    @media screen and (min-width: 320px) and (max-width: 440px) {
      display: block;
    }

    .project-content-sec {
      @media screen and (min-width: 1024px) and (max-width: 1350px) {
        margin-right: 30px;
      }

      .heading-text {
        font-size: 26px;
        font-weight: 500;
        color: #F0F0DC;
        font-family: $regulerFont;
        margin: 0px;

        @media screen and (min-width: 1600px) and (max-width: 2560px) {
          font-size: 30px;
        }
      }

      .title-text {
        font-size: 15px;
        font-weight: 400;
        color: #F0F0DC;
        font-family: $regulerFont;
        text-decoration: underline;

        @media screen and (min-width: 1600px) and (max-width: 2560px) {
          font-size: 22px;
        }
      }

      .info-text {
        font-size: 14px;
        font-weight: 300;
        color: #F0F0DC;
        font-family: $regulerFont;
        max-width: 250px;

        // max-height: 144px;
        // overflow: scroll;
        // &::-webkit-scrollbar {
        //   display: none;
        // }
        @media screen and (min-width: 320px) and (max-width: 440px) {
          max-width: none ;
        }

        @media screen and (min-width: 1400px) and (max-width: 2560px) {
          max-width: 676px;
        }

        @media screen and (min-width: 1600px) and (max-width: 2560px) {
          font-size: 17px;
        }
      }

      .info-text-view {
        font-size: 14px;
        font-weight: 300;
        padding-top: 87.7px;
        font-family: $regulerFont;
        max-width: 250px;
        max-height: 144px;

        @media screen and (min-width: 320px) and (max-width: 440px) {
          padding-top: 30.7px;
        }

        @media screen and (min-width: 1600px) and (max-width: 2560px) {
          font-size: 17px;
        }
      }
    }
  }
}

.lets-work-together-box {
  margin: 85px 0px 126.5px;

  .lets-work-togethe {
    margin: 0 auto;
    max-width: 212px;
    padding: 12px 24px 12px 24px;
    text-align: center;
    border-radius: 50px;
    cursor: pointer;
    color: #F0F0DC;
    font-size: 18px;
    font-weight: 500;
    font-family: $dmSansFont;
    background-color: rgba(49, 116, 45, 0.25);
    border: 1px solid #31742d;
  }
}