@import "../../../assets/scss/veriables.scss";

.work-card-box {
  padding: 40.25px 0;
  display: block;
  height: 100%;

  .card-content {
    background: #27241e !important;
    padding: 0 auto;
    border-radius: 12px;
    border: 1px solid #6A665E;
    @media screen and (min-width: 320px) and (max-width: 440px) {
      height: 352px;
    }
    .card-detail-sec {
      padding: 0 25px 30px 25px;
      @media screen and (min-width: 320px) and (max-width: 440px) {
        padding: 0 8px 12px 8px;
      }
      .card-title {
        font-size: 26px;
        font-weight: 500;
        font-family: $dmSansFont;
        text-transform: uppercase;
        text-align: start;
        color: #F0F0DC;
        @media screen and (min-width: 320px) and (max-width: 440px) {
          font-size: 22px !important;
        }
      }
      .card-para {
        font-size: 18px;
        font-family: $regulerFont;
        font-weight: 400;
        color: #F0F0DC;
        text-align: start;
        @media screen and (min-width: 320px) and (max-width: 440px) {
          font-size: 12px !important;
        }
      }
    }
    .card-img {
      max-width: unset !important;
      // @media screen and (min-width: 320px) and (max-width: 440px) {
      //   height: 27% !important;
      // }
      @media screen and (min-width: 320px) and (max-width: 440px) {
        width: 241px;
      }
      @media screen and (min-width: 440px) and (max-width: 1024px) {
        width: 100% !important;
      }
      @media screen and (min-width: 1025px) and (max-width: 1440px) {
        width: 95% !important;
      }
      @media screen and (min-width: 1200px) and (max-width: 2580px) {
        min-width: 100% !important;
      }
    }
    .card-img-chotadhobi {
      max-width: unset !important;
      width: 1200px;
      height: 450px;
      border-radius: 8px 8px 0px 0px;
      @media screen and (min-width: 320px) and (max-width: 440px) {
        width: 241px !important;
        height: 92px !important;
      }
      @media screen and (min-width: 440px) and (max-width: 834px) {
        width: 491px !important;
        height: 172px !important;
      }
      @media screen and (min-width: 835px) and (max-width: 1024px) {
        width: 619px !important;
        height: 232px !important;
      }
      @media screen and (min-width: 1025px) and (max-width: 1440px) {
        width: 800px !important;
        height: 300px !important;
      }
    }
  }
}
.react-3d-carousel {
  height: 600px !important;
  @media screen and (min-width: 320px) and (max-width: 375px) {
    height: 350px !important;
  }
  @media screen and (min-width: 375px) and (max-width: 440px) {
    height: 300px !important;
  }
}
// .react-3d-carousel .slider-container .slider-left div,
// .react-3d-carousel .slider-container .slider-right div {
//   display: none !important;
// }

.react-3d-carousel .slider-container .slider-left {
  right: 119% !important;
  @media screen and (min-width: 1441px) and (max-width: 1920px) {
    right: 132% !important;
  }
}

.react-3d-carousel .slider-container .slider-right {
  left: 119% !important;
  @media screen and (min-width: 1441px) and (max-width: 1920px) {
    left: 132% !important;
  }
}

.react-3d-carousel .slider-container .slider-left div,
.react-3d-carousel .slider-container .slider-right div {
  border: none !important;
}
