@import "../../../assets/scss/veriables.scss";

// project page , about us page , contact us page footer without blur
.footer-main-container {
  background-color: rgba(39, 36, 30, 1);
  padding: 75px 156px;
  @media screen and (min-width: 320px) and (max-width: 440px) {
    padding: 25px 40px 100px 40px;
  }
  @media (width: 768px) {
    padding: 75px 55px;
  }
  @media screen and (min-width: 768px) and (max-width: 1194px) {
    padding: 76px 60px;
  }

  .divider {
    background-color: white !important;
  }
  .footer-first-sec {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    padding-bottom: 40px;
    @media screen and (min-width: 320px) and (max-width: 440px) {
      display: block;
    }
    .footer-first-sub-part {
      max-width: 180px;
      @media screen and (min-width: 320px) and (max-width: 440px) {
        margin: 0 auto;
        text-align: center;
        max-width: none;
      }
      @media (max-width: 991px) {
        max-width: 164px;
      }
    }
    .footer-first-sub-sec {
      display: flex;
      gap: 191px;
      @media screen and (min-width: 320px) and (max-width: 440px) {
        gap: 60px;
        justify-content: space-around;
      }
      //   @media (max-width: 768px) {
      //    gap: 40px;
      //   }
      @media (max-width: 991px) {
        gap: 25px !important;
      }
      @media (max-width: 1024px) {
        gap: 60px;
      }
    }
    .astrik-img {
      padding-right: 10px;
    }
    .astrik-title-text {
      font-size: 18px;
      font-family: $soraFont;
      font-weight: 400;
      color: #FFFEE0;
      text-transform: uppercase;
      vertical-align: top;
    }
    .astrik-add-text {
      font-size: 14px;
      font-family: $regulerFont;
      font-weight: 300;
      line-height: 18px;
      color: #F0F0DC;
    }
    .astrik-email-text {
      font-size: 14px;
      font-family: $dmSansFont;
      font-weight: 400;
      line-height: 18px;
    }
    .footer-head-text {
      font-size: 16px;
      font-family: $dmSansFont;
      font-weight: 700;
      color: #FFFEE0;
      text-transform: uppercase;
    }
    .footer-para-text {
      font-size: 16px;
      font-weight: 400;
      color: #FFFEE0;
      font-family: $regulerFont;
    }
    .field-label-text {
      width: 100%;
      min-width: 300px;
      .MuiInputBase-root {
        border-radius: 12px;
        // background-color: rgba(32, 29, 23, 1);
        background-color: #201d17;
        color: #f0f0f0;
        max-height: 43px;
        .MuiOutlinedInput-notchedOutline {
          border: 0.5px solid #6a665e;
        }
      }
      .MuiInputLabel-root {
        font-size: 18px;
        font-family: $regulerFont;
        font-weight: 300;
        color: #f0f0f0 !important;
        top: -3px;
      }
      .MuiOutlinedInput-notchedOutline {
        border: none;
      }
      input,
      select {
        color: #fff !important;
        -webkit-text-fill-color: #fff !important;
        -webkit-background-clip: text !important;
        background-clip: text !important;
      }
    }
    .enter-underline-text {
      text-decoration: underline;
    }
    .press-enter-text {
      font-size: 10px;
      font-family: $regulerFont;
      font-weight: 400;
      color: #FFFEE0;
      text-align: end;
    }
  }
  .footer-second-sec {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 40px;
    @media screen and (min-width: 320px) and (max-width: 440px) {
      display: block;
      padding-top: 5px;
    }
    .astrik-copy-right-text {
      font-size: 14px;
      font-family: $regulerFont;
      font-weight: 500;
      color: #FFFEE0;
      word-wrap: break-word;
      @media screen and (min-width: 320px) and (max-width: 440px) {
        text-align: center;
      }
    }
    .astrik-policy-sec {
      display: flex;
      justify-content: space-between;
      .astrik-policy-text {
        font-size: 12px;
        font-family: $regulerFont;
        font-weight: 300;
        color: #FFFEE0;
        word-wrap: break-word;
        @media (max-width: 320px) {
          font-size: 9px;
        }
      }
    }

    @media screen and (min-width: 320px) and (max-width: 440px) {
      .social-media-icons {
        padding: 10px 0px 10px 0px;
        text-align: center;
      }
    }
  }
}

.footer-illustrations-text {
  font-size: 10px;
  font-family: $regulerFont;
  font-weight: 400;
  word-wrap: break-word;
  margin-top: 12px;
  @media screen and (min-width: 320px) and (max-width: 440px) {
    display: none;
  }
}

// home page blur footer
.footer-main-container-blur {
  // max-width: 1512px;
  // max-height: 386px;
  background-color: rgba(39, 36, 30, 1);
  padding: 75px 156px;
  position: relative;
  @media screen and (min-width: 320px) and (max-width: 440px) {
    padding: 25px 40px 100px 40px;
  }
  @media (width: 768px) {
    padding: 75px 55px;
  }
  @media screen and (min-width: 768px) and (max-width: 1194px) {
    padding: 76px 60px;
  }

  &::before {
    content: "";
    position: absolute;
    top: -30px;
    width: 100%;
    height: 54px;
    left: 0;
    background: linear-gradient(0deg, #27241e 0%, rgba(39, 36, 30, 0) 100%);
    backdrop-filter: blur(5px);
    transform: scaleY(1.5);
    @media screen and (min-width: 320px) and (max-width: 440px) {
      transform: scaleY(1.1);
    }
  }

  .divider {
    background-color: white !important;
  }
  .footer-first-sec {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    padding-bottom: 40px;
    @media screen and (min-width: 320px) and (max-width: 440px) {
      display: block;
    }
    .footer-first-sub-part {
      max-width: 180px;
      @media screen and (min-width: 320px) and (max-width: 440px) {
        margin: 0 auto;
        text-align: center;
        max-width: none;
      }
      @media (max-width: 991px) {
        max-width: 164px;
      }
    }
    .footer-first-sub-sec {
      display: flex;
      gap: 191px;
      @media screen and (min-width: 320px) and (max-width: 440px) {
        gap: 60px;
        justify-content: space-around;
      }
      //   @media (max-width: 768px) {
      //    gap: 40px;
      //   }
      @media (max-width: 991px) {
        gap: 25px !important;
      }
      @media (max-width: 1024px) {
        gap: 60px;
      }
    }
    .astrik-img {
      padding-right: 10px;
    }
    .astrik-title-text {
      font-size: 18px;
      font-family: $soraFont;
      font-weight: 400;
      text-transform: uppercase;
      vertical-align: top;
    }
    .astrik-add-text {
      font-size: 14px;
      font-family: $regulerFont;
      font-weight: 300;
      line-height: 18px;
      color: #F0F0DC;
    }
    .astrik-email-text {
      font-size: 14px;
      font-family: $dmSansFont;
      font-weight: 400;
      line-height: 18px;
    }
    .footer-head-text {
      font-size: 16px;
      font-family: $dmSansFont;
      font-weight: 700;
      color: #FFFEE0;
      text-transform: uppercase;

    }
    .footer-para-text {
      font-size: 16px;
      font-weight: 400;
      color: #FFFEE0;
      font-family: $regulerFont;
    }
    .field-label-text {
      width: 100%;
      min-width: 300px;
      .MuiInputBase-root {
        border-radius: 12px;
        // background-color: rgba(32, 29, 23, 1);
        background-color: #201d17;
        color: #f0f0f0;
        max-height: 43px;
        .MuiOutlinedInput-notchedOutline {
          border: 0.5px solid #6a665e;
        }
      }
      .MuiInputLabel-root {
        font-size: 18px;
        font-family: $regulerFont;
        font-weight: 300;
        color: #f0f0f0 !important;
        top: -3px;
      }
      .MuiOutlinedInput-notchedOutline {
        border: none;
      }
      input,
      select {
        color: #fff !important;
        -webkit-text-fill-color: #fff !important;
        -webkit-background-clip: text !important;
        background-clip: text !important;
      }
    }
    .enter-underline-text {
      text-decoration: underline;
    }
    .press-enter-text {
      font-size: 10px;
      font-family: $regulerFont;
      font-weight: 400;
      color: #FFFEE0;
      text-align: end;
    }
  }
  .footer-second-sec {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 40px;
    @media screen and (min-width: 320px) and (max-width: 440px) {
      display: block;
      padding-top: 5px;
    }
    .astrik-copy-right-text {
      font-size: 14px;
      font-family: $regulerFont;
      font-weight: 500;
      color: #FFFEE0;
      word-wrap: break-word;
      @media screen and (min-width: 320px) and (max-width: 440px) {
        text-align: center;
      }
    }
    .astrik-policy-sec {
      display: flex;
      justify-content: space-between;
      .astrik-policy-text {
        font-size: 12px;
        font-family: $regulerFont;
        font-weight: 300;
        color: #FFFEE0;
        word-wrap: break-word;
        @media (max-width: 320px) {
          font-size: 9px;
        }
      }
    }

    @media screen and (min-width: 320px) and (max-width: 440px) {
      .social-media-icons {
        padding: 10px 0px 10px 0px;
        text-align: center;
      }
    }
  }
}

.footer-illustrations-text {
  font-size: 10px;
  font-family: $regulerFont;
  font-weight: 400;
  word-wrap: break-word;
  margin-top: 12px;
  @media screen and (min-width: 320px) and (max-width: 440px) {
    display: none;
  }
}
